<template>
	<div>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		computed: {},
		created() {
			
		},
		mounted() {
			
		},
		methods:{}
	}
</script>

<style lang="less">
</style>
